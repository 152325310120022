export default [
    // Administration
    {id: 'BACKOFFICE_USER', name: 'resources.menu.admin.user.name'},
    {id: 'BACKOFFICE_ROLE', name: 'resources.menu.admin.rol.name'},

    // Reports
    {id: 'REPORT_LABORATORY', name: 'resources.menu.report.laboratory.name'},
    {id: 'REPORT_EXPORT', name: 'resources.menu.report.exports.name'},

    // Backoffice
    {id: 'BACKOFFICE_LOCALIZATION', name: 'resources.menu.backoffice.localization.name'},
    {id: 'BACKOFFICE_LABORATORY', name: 'resources.menu.backoffice.laboratory.name'},
    {id: 'BACKOFFICE_INSTRUMENT', name: 'resources.menu.backoffice.instrument.name'},
    {id: 'BACKOFFICE_EXPORTER', name: 'resources.menu.backoffice.exporter.name'},
    {id: 'BACKOFFICE_CUSTOMS', name: 'resources.menu.backoffice.customs_house.name'},
    {id: 'BACKOFFICE_PORT', name: 'resources.menu.backoffice.port.name'},
    {id: 'BACKOFFICE_SAMPLING_LOCATION', name: 'resources.menu.backoffice.sampling_location.name'},
    {id: 'BACKOFFICE_NORMATIVE', name: 'resources.menu.backoffice.normative.name'},
    
    // Operations
    {id: 'BACKOFFICE_WEIGHT_REPORT_OPERATION', name: 'resources.menu.forms.weight_report_operation.name'},
    {id: 'BACKOFFICE_LABORATORY_REGISTRY', name: 'resources.menu.forms.laboratory.name'},
    
    // SACOP
    {id: 'BACKOFFICE_REQUEST_FOR_ACTION', name: 'resources.menu.backoffice.requestForAction.name'},
    {id: 'BACKOFFICE_ANALYSIS_OF_SHARES', name: 'resources.menu.backoffice.analysisOfShares.name'},
    {id: 'BACKOFFICE_IMPLEMENTATION_OF_ACTION', name: 'resources.menu.backoffice.implementationOfAction.name'},
    {id: 'BACKOFFICE_CLOSING_OF_SHARES', name: 'resources.menu.backoffice.closingOfShares.name'},
    {id: 'BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION', name: 'resources.menu.backoffice.verificationEffectivenessAction.name'},
    {id: 'BACKOFFICE_MASTER_OF_SHARES', name: 'resources.menu.backoffice.masterOfShares.name' },
    {id: 'BACKOFFICE_SHARES_COMMITEE', name: 'resources.menu.backoffice.sharesCommitee.name'},
]
