import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    TextInput,
    required,
    SelectInput,
    SimpleForm,
    DateInput,
    Loading,
    Exporter,
    useRecordContext,
    FileInput,
    FileField,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    AutocompleteInput,
} from "react-admin";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import { ReactElement, useEffect, useState } from "react";
import { Authority, Localization, Motive, Regulation, Sacop, TypeOfGeneratingEntity, User } from "../../../../shared/types/types";
import fetchDataService from "../../../../shared/utilities/fetchs";
import { typeOfGeneratingEntityOptions } from "./data/typeOfGeneratingEntityOptions";

interface RequestForActionFormProps {
    edit?: boolean;
    formTitle: ReactElement;
}

const RequestForActionForm = ({ edit, formTitle }: RequestForActionFormProps) => {
    const [loading, setLoading] = useState(true);
    const [motive, setMotive] = useState<Motive[]>();
    const [typeOfGeneratingEntity, setTypeOfGeneratingEntity] = useState<TypeOfGeneratingEntity[]>();
    const [resposibleForAnalysis, setResposibleForAnalysis] = useState<User[]>();
    const [authority, setAuthority] = useState<Authority[]>();
    const [localization, setLocalization] = useState<Localization[]>();
    const [exporter, setExporter] = useState<Exporter[]>();
    const [regulation, setRegulation] = useState<Regulation[]>();
    const [additionalFieldsToShow, setAdditionalFieldsToShow] = useState({
        isArea: false,
        isAuthority: false,
        isCustomer: false,
        isOthers: false
    });
    const record = useRecordContext<Sacop>();

    const loadAdditionalFieldsToShow = (typeOfGeneratingEntityId: number) => {
        setAdditionalFieldsToShow({
            isArea: typeOfGeneratingEntityId === typeOfGeneratingEntityOptions["area"],
            isAuthority: typeOfGeneratingEntityId === typeOfGeneratingEntityOptions["authority"],
            isCustomer: typeOfGeneratingEntityId === typeOfGeneratingEntityOptions["customer"],
            isOthers: typeOfGeneratingEntityId === typeOfGeneratingEntityOptions["others"]
        });
    }

    useEffect(() => {
        const { getMotiveData,
            getTypeOfGeneratingEntityData,
            getResponsibleForAnalysisData,
            getAuthorityData,
            getLocalizationData,
            getExporterData,
            getRegulationData } = fetchDataService();
        Promise.all([
            getMotiveData(),
            getTypeOfGeneratingEntityData(),
            getResponsibleForAnalysisData(),
            getAuthorityData(),
            getLocalizationData(),
            getExporterData(),
            getRegulationData(),
        ]).then(([motiveResponse,
            typeOfGeneratingEntityResponse,
            responsibleForAnalysisResponse,
            authorityResponse,
            localizationResponse,
            exporterResponse,
            regulationResponse]) => {
            setMotive(motiveResponse.data);
            setTypeOfGeneratingEntity(typeOfGeneratingEntityResponse.data);
            setResposibleForAnalysis(responsibleForAnalysisResponse.data);
            setAuthority(authorityResponse.data);
            setLocalization(localizationResponse.data);
            setExporter(exporterResponse.data);
            setRegulation(regulationResponse.data);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (edit) {
            const { typeOfGeneratingEntityId } = record;
            loadAdditionalFieldsToShow(typeOfGeneratingEntityId);
        }
    }, [edit]);

    if (loading) {
        return <Loading />;
    }

    return (
        <SimpleForm
            toolbar={<FormToolbar edit={edit ? true : undefined} />}
            sx={{ mt: 3, mb: 3 }}
        >
            <Grid container lg={10} lgOffset={1} columnSpacing={2}>
                <Grid xs={12} sx={{ mb: 2 }}>
                    <Typography variant="h6">{formTitle}</Typography>
                </Grid>

                <Grid container md={12}>
                    <Grid xs={12} md={4}>
                        <DateInput
                            source="dateRequestAction"
                            label="resources.sacop.fields.dateRequestAction"
                            variant="outlined"
                            validate={required()}
                            helperText={false}
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={12} md={4}>
                        <TextInput
                            source="generatingEntity"
                            label="resources.sacop.fields.generatingEntity"
                            fullWidth
                            validate={required()}
                        />
                    </Grid>

                    <Grid xs={12} md={4}>
                        <SelectInput
                            source="typeOfGeneratingEntityId"
                            label="resources.sacop.fields.typeOfGeneratingEntityId"
                            fullWidth
                            validate={required()}
                            choices={typeOfGeneratingEntity?.map(item => ({ id: item.id, name: item.nameOfGeneratingEntity }))}
                            onChange={(e) => {
                                const value = e.target.value;
                                loadAdditionalFieldsToShow(value);
                            }}
                        />
                    </Grid>
                    {additionalFieldsToShow.isAuthority && (
                        <Grid xs={12} md={4}>
                            <SelectInput
                                source="authorityId"
                                label="resources.sacop.fields.authorityId"
                                fullWidth
                                choices={authority?.map(item => ({ id: item.id, name: item.authorityName }))}
                            />
                        </Grid>
                    )}

                    {additionalFieldsToShow.isArea && (
                        <Grid xs={12} md={4}>
                            <ReferenceArrayInput
                                source="localizationId"
                                reference="localization"
                                perPage={999}
                                filter={{ active: true }}
                            >
                                <AutocompleteInput
                                    label="resources.sacop.fields.localizationId"
                                    optionText={(choice) => `${choice.code} - ${choice.name}`}
                                    debounce={500}
                                />
                            </ReferenceArrayInput>
                        </Grid>
                    )}

                    {additionalFieldsToShow.isCustomer && (
                        <Grid xs={12} md={4}>
                            <ReferenceArrayInput
                                source="exporterId"
                                reference="exporter"
                                perPage={999}
                                filter={{ active: true }}
                            >
                                <AutocompleteInput
                                    label="resources.sacop.fields.exporterId"
                                    optionText={(choice) => `${choice.taxpayerReference} - ${choice.name}`}
                                    debounce={500}
                                />
                            </ReferenceArrayInput>
                        </Grid>
                    )}

                    {additionalFieldsToShow.isOthers && (
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="freeText"
                                label="resources.sacop.fields.freeText"
                                fullWidth
                            />
                        </Grid>
                    )}

                    <Grid xs={12} md={4}>
                        <SelectInput
                            source="motiveId"
                            label="resources.sacop.fields.motiveId"
                            fullWidth
                            validate={required()}
                            choices={motive?.map(item => ({ id: item.id, name: item.motiveName }))}
                        />
                    </Grid>

                    <Grid xs={12} md={4}>
                        <SelectInput
                            source="regulationId"
                            label="resources.sacop.fields.regulationId"
                            fullWidth
                            validate={required()}
                            choices={regulation?.map(item => ({ id: item.id, name: item.regulationName }))}
                        />
                    </Grid>

                    {edit && (
                        <Grid xs={12} md={4}>
                            <TextInput
                                source="whoReports.fullName"
                                label="resources.sacop.fields.whoReports"
                                fullWidth
                                disabled
                            />
                        </Grid>
                    )}
                    <Grid xs={12} md={4}>
                        <SelectInput
                            source="responsibleForAnalysisId"
                            label="resources.sacop.fields.responsibleForAnalysisId"
                            fullWidth
                            validate={required()}
                            choices={resposibleForAnalysis?.map(item => ({ id: item.id, name: item.fullName }))}
                        />
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextInput
                            source="problemDescription"
                            label="resources.sacop.fields.problemDescription"
                            validate={required()}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextInput
                            source="immediateActions"
                            label="resources.sacop.fields.immediateActions"
                            validate={required()}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextInput
                            source="textualEvidence"
                            label="resources.sacop.fields.textualEvidence"
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid xs={12} md={6} sx={{ pl: 3 }}>
                        <FileInput
                            source="evidenceOfRequest"
                            label="resources.sacop.fields.evidence">
                            <FileField source="src" title="title" />
                        </FileInput>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

export default RequestForActionForm;