import { useState } from 'react';
import Box from '@mui/material/Box';

import { useTranslate, DashboardMenuItem, MenuProps, useSidebarState, usePermissions } from 'react-admin';

import SubMenu from './SubMenu';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BookIcon from '@mui/icons-material/Book';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import FlakyIcon from '@mui/icons-material/Flaky';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { AccountTree, AdminPanelSettings, Apartment, ArrowOutward, DirectionsBoat, Handyman, LocationOn, Warehouse } from '@mui/icons-material';
import MenuItemLinkDCore from './MenuItemLinkDCore';

type MenuName = 'menuBackoffice' | 'menuOperation' | 'menuAdministration' | 'menuSacop' | 'menuReport';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuBackoffice: true,
        menuOperation: true,
        menuAdministration: true,
        menuReport: true,
        menuSacop: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const { isLoading, permissions } = usePermissions();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        isLoading
            ? <></>
            : (
                <Box
                    sx={{
                        width: open ? 200 : 50,
                        marginTop: 1,
                        marginBottom: 1,
                        transition: theme =>
                            theme.transitions.create('width', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                            }),
                    }}
                >
                    <DashboardMenuItem
                        sx={{ '&:hover': { backgroundColor: 'secondary.main', color: 'primary.contrastText' } }}
                    />
                    {
                        (permissions.some(x => x === 'BACKOFFICE_USER_R') || permissions.some(x => x === 'BACKOFFICE_ROLE_R') ? (
                            <SubMenu
                                handleToggle={() => handleToggle('menuAdministration')}
                                isOpen={state.menuAdministration}
                                name="resources.menu.admin.name"
                                icon={<BusinessIcon />}
                                dense={dense}
                                permission={['BACKOFFICE_USER_R', 'BACKOFFICE_ROLE_R']}
                            >

                                <MenuItemLinkDCore
                                    to="/user"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.admin.user.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<SupportAgentIcon />}
                                    dense={dense}
                                    permission='BACKOFFICE_USER_R'
                                />

                                <MenuItemLinkDCore
                                    to="/rol"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.admin.rol.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<AdminPanelSettings />}
                                    dense={dense}
                                    permission='BACKOFFICE_ROLE_R'
                                />
                            </SubMenu>
                        ) : (
                            <></>
                        )
                        )
                    }

                    {
                        (permissions.some(x => x === 'REPORT_LABORATORY_R') || permissions.some(x => x === 'REPORT_EXPORT_R') ? (
                            <SubMenu
                                handleToggle={() => handleToggle('menuReport')}
                                isOpen={state.menuReport}
                                name="resources.menu.report.name"
                                icon={<BusinessIcon />}
                                dense={dense}
                                permission={['REPORT_LABORATORY_R', 'REPORT_EXPORT_R']}
                            >

                                <MenuItemLinkDCore
                                    to="/report/laboratory"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.report.laboratory.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<SupportAgentIcon />}
                                    dense={dense}
                                    permission='REPORT_LABORATORY_R'
                                />

                                <MenuItemLinkDCore
                                    to="/report/exports"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.report.exports.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<AdminPanelSettings />}
                                    dense={dense}
                                    permission='REPORT_EXPORT_R'
                                />
                            </SubMenu>
                        ) : (
                            <></>
                        )
                        )
                    }

                    {
                        (permissions.some(x => x === 'BACKOFFICE_LOCALIZATION_R') ||
                            permissions.some(x => x === 'BACKOFFICE_LABORATORY_R') ||
                            permissions.some(x => x === 'BACKOFFICE_INSTRUMENT_R') ||
                            permissions.some(x => x === 'BACKOFFICE_EXPORTER_R') ||
                            permissions.some(x => x === 'BACKOFFICE_CUSTOMS_R') ||
                            permissions.some(x => x === 'BACKOFFICE_PORT_R') ||
                            permissions.some(x => x === 'BACKOFFICE_SAMPLING_LOCATION_R') ||
                            permissions.some(x => x === 'BACKOFFICE_NORMATIVE_R') ? (
                            <SubMenu
                                handleToggle={() => handleToggle('menuBackoffice')}
                                isOpen={state.menuBackoffice}
                                name="resources.menu.backoffice.name"
                                icon={<BusinessIcon />}
                                dense={dense}
                                permission={['BACKOFFICE_LOCALIZATION_R',
                                    'BACKOFFICE_LABORATORY_R',
                                    'BACKOFFICE_INSTRUMENT_R',
                                    'BACKOFFICE_EXPORTER_R',
                                    'BACKOFFICE_CUSTOMS_R',
                                    'BACKOFFICE_PORT_R',
                                    'BACKOFFICE_SAMPLING_LOCATION_R',
                                    'BACKOFFICE_NORMATIVE_R']}
                            >
                                <MenuItemLinkDCore
                                    to="/localization"
                                    state={{ _scrollToTop: true }}
                                    primaryText="Centro de Costo"
                                    leftIcon={<Apartment />}
                                    dense={dense}
                                    permission='BACKOFFICE_LOCALIZATION_R'
                                />

                                <MenuItemLinkDCore
                                    to="/laboratory"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.backoffice.laboratory.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<BiotechIcon />}
                                    dense={dense}
                                    permission='BACKOFFICE_LABORATORY_R'
                                />
                                <MenuItemLinkDCore
                                    to="/instrument"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.backoffice.instrument.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<Handyman />}
                                    dense={dense}
                                    permission='BACKOFFICE_INSTRUMENT_R'
                                />
                                <MenuItemLinkDCore
                                    to="/exporter"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.backoffice.exporter.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<ArrowOutward />}
                                    dense={dense}
                                    permission='BACKOFFICE_EXPORTER_R'
                                />

                                <MenuItemLinkDCore
                                    to="/customsHouse"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.backoffice.customs_house.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<Warehouse />}
                                    dense={dense}
                                    permission='BACKOFFICE_CUSTOMS_R'
                                />

                                <MenuItemLinkDCore
                                    to="/port"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.backoffice.port.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<DirectionsBoat />}
                                    dense={dense}
                                    permission='BACKOFFICE_PORT_R'
                                />

                                <MenuItemLinkDCore
                                    to="/samplingLocation"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.backoffice.sampling_location.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<LocationOn />}
                                    dense={dense}
                                    permission='BACKOFFICE_SAMPLING_LOCATION_R'
                                />

                                <MenuItemLinkDCore
                                    to="/normative"
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate(`resources.menu.backoffice.normative.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<AccountTree />}
                                    dense={dense}
                                    permission='BACKOFFICE_NORMATIVE_R'
                                />

                            </SubMenu>
                        ) : (
                            <></>
                        )
                        )
                    }

                    {
                        (permissions.some(x => x === 'BACKOFFICE_WEIGHT_REPORT_OPERATION_R') || permissions.some(x => x === 'BACKOFFICE_LABORATORY_REGISTRY_R') ? (
                            <SubMenu
                                handleToggle={() => handleToggle('menuOperation')}
                                isOpen={state.menuOperation}
                                name="resources.menu.forms.name"
                                icon={<AssignmentIcon />}
                                dense={dense}
                                permission={['BACKOFFICE_WEIGHT_REPORT_OPERATION_R',
                                    'BACKOFFICE_LABORATORY_REGISTRY_R']}
                            >
                                <MenuItemLinkDCore
                                    to="/weightReportOperation"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<AssignmentIcon />}
                                    primaryText="resources.menu.forms.weight_report_operation.name"
                                    dense={dense}
                                    permission='BACKOFFICE_WEIGHT_REPORT_OPERATION_R'
                                />
                                <MenuItemLinkDCore
                                    to="/laboratoryRegistry"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<ScienceIcon />}
                                    primaryText="resources.menu.forms.laboratory.name"
                                    dense={dense}
                                    permission='BACKOFFICE_LABORATORY_REGISTRY_R'
                                />
                            </SubMenu>
                        ) : (
                            <></>
                        )
                        )
                    }

                    {
                        (permissions.some(x => x === 'BACKOFFICE_REQUEST_FOR_ACTION_R') ||
                        permissions.some(x => x === 'BACKOFFICE_ANALYSIS_OF_SHARES_R') ||
                        permissions.some(x => x === 'BACKOFFICE_IMPLEMENTATION_OF_ACTION_R') ||
                        permissions.some(x => x === 'BACKOFFICE_CLOSING_OF_SHARES_R') ||
                        permissions.some(x => x === 'BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION_R') ||
                        permissions.some(x => x === 'BACKOFFICE_MASTER_OF_SHARES_R') ||
                        permissions.some(x => x === 'BACKOFFICE_SHARES_COMMITEE_R') ? (
                            <SubMenu
                                handleToggle={() => handleToggle('menuSacop')}
                                isOpen={state.menuSacop}
                                name="resources.menu.sacop.name"
                                icon={<AssignmentIcon />}
                                dense={dense}
                                permission={['BACKOFFICE_REQUEST_FOR_ACTION_R',
                                    'BACKOFFICE_ANALYSIS_OF_SHARES_R',
                                    'BACKOFFICE_IMPLEMENTATION_OF_ACTION_R',
                                    'BACKOFFICE_CLOSING_OF_SHARES_R',
                                    'BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION_R',
                                    'BACKOFFICE_MASTER_OF_SHARES_R',
                                    'BACKOFFICE_SHARES_COMMITEE_R']}
                            >
                                <MenuItemLinkDCore
                                    to="/requestForAction"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<BookIcon />}
                                    primaryText="resources.menu.backoffice.requestForAction.name"
                                    dense={dense}
                                    permission='BACKOFFICE_REQUEST_FOR_ACTION_R'

                                />
                                <MenuItemLinkDCore
                                    to="/analysisOfShares"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<NoteAltIcon />}
                                    primaryText="resources.menu.backoffice.analysisOfShares.name"
                                    dense={dense}
                                    permission='BACKOFFICE_ANALYSIS_OF_SHARES_R'
                                />
                                <MenuItemLinkDCore
                                    to="/implementationOfAction"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<Diversity3Icon />}
                                    primaryText="resources.menu.backoffice.implementationOfAction.name"
                                    dense={dense}
                                    permission='BACKOFFICE_IMPLEMENTATION_OF_ACTION_R'
                                />
                                <MenuItemLinkDCore
                                    to="/closingOfShares"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<FlakyIcon />}
                                    primaryText="resources.menu.backoffice.closingOfShares.name"
                                    dense={dense}
                                    permission='BACKOFFICE_CLOSING_OF_SHARES_R'
                                />
                                <MenuItemLinkDCore
                                    to="/verificationEffectivenessAction"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<ContentPasteSearchIcon />}
                                    primaryText="resources.menu.backoffice.verificationEffectivenessAction.name"
                                    dense={dense}
                                    permission='BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION_R'
                                />
                                <MenuItemLinkDCore
                                    to="/masterOfShares"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<AssignmentIndIcon />}
                                    primaryText="resources.menu.backoffice.masterOfShares.name"
                                    dense={dense}
                                    permission='BACKOFFICE_MASTER_OF_SHARES_R'
                                />
                                <MenuItemLinkDCore
                                    to="/sharesCommitee"
                                    state={{ _scrollToTop: true }}
                                    leftIcon={<Diversity2Icon />}
                                    primaryText="resources.menu.backoffice.sharesCommitee.name"
                                    dense={dense}
                                    permission='BACKOFFICE_SHARES_COMMITEE_R'
                                />

                            </SubMenu>
                        ) : (
                            <></>
                        )
                        )
                    }
                </Box>
            )
    );
};

export default Menu;
