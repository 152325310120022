import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
//import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  Form,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
  useAuthProvider,
  removeDoubleSlashes,
  useBasename,
} from 'react-admin';

import loginImage from "./login.png"
import MfaCodeSetup from '../user/security/MFA/MfaCodeSetup';
import { useNavigate } from "react-router-dom";
import RecoveryCode from '../user/security/MFA/RecoveryCode';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const authProvider = useAuthProvider();
  const location = useLocation();
  const basename = useBasename();
  const locationState = location.state as any;
  const nextPathName = locationState && locationState.nextPathname;
  const nextSearch = locationState && locationState.nextSearch;
  const afterLoginUrl = removeDoubleSlashes(
      `${basename}/`
  );
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [showRecoveryCode, setShowRecoveryCode] = useState(false);

  const getRedirectUrl = (pathname: string) => {
    return pathname ? pathname : nextPathName + nextSearch || afterLoginUrl;
  }

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    authProvider.login(auth).then((response) => {
      if (response.data.isMfaRequired) {
        setIsMfaRequired(true);
      }
      else {
        navigate(getRedirectUrl(location.state ? (location.state as any).nextPathname : '/'))
      }
    })
    .catch((error) => {
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
        {
          type: 'error',
          messageArgs: {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                  ? error.message
                  : undefined,
          },
        }
      );
      setLoading(false);
    })
    .finally(() => {
      setLoading(false);
    })
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 8,
          }}
        >
          <Grid container>
            <Grid
              component="img"
              src={loginImage}
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                // backgroundImage: {loginImage},
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>

                {isMfaRequired ?
                  <Stack gap={4} marginTop={5}>
                    <MfaCodeSetup initSetup={false} show={!showRecoveryCode}/>
                    <RecoveryCode showSetup={() => setShowRecoveryCode(!showRecoveryCode)}/>
                  </Stack>
                  : 
                   <>
                      <TextInput
                        source="username"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={translate('ra.auth.username')}
                        name="username"
                        autoComplete="username"
                        autoFocus
                      />

                      <TextInput
                        source="password"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={translate('ra.auth.password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />

                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label={translate('resources.auth.rememberme')}
                      />

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Sign in
                      </Button>

                      <Grid container>
                        <Grid item xs>
                          <Link href="#" variant="body2">
                            Forgot password?
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link href="#" variant="body2">
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid>
                      </Grid>
                   </>
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
  username?: string;
  password?: string;
}
